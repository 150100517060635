<template>
    <b-card :title="$t('Edit AD')" v-if="!loading">
        <validation-observer ref="simpleRules">
            <b-row class="match-height">
                <b-col
                    md="6" class="mb-3">
                    <p class="text-center font-weight-bold">{{ $t('homepageImage') }}</p>
                    <b-img fluid :src="AdInfo.image_full_path" :alt="$t('Ad\'s Image')" class="image-prev"/>
                </b-col>
                <b-col
                    md="6" class="mb-3">
                    <p class="text-center font-weight-bold">{{ $t('bannerImage') }}</p>
                    <b-img fluid :src="AdInfo.second_image_full_path" :alt="$t('Ad\'s Image')" class="image-prev"/>
                </b-col>
                <b-col
                    md="6"
                >
                    <b-form-group
                        :label="$t('bannerImage')"
                        label-for="bannerImage"
                    >
                        <b-form-file
                            id="bannerImage"
                            accept="image/*"
                            v-model="AdInfo.image"
                            @input="uploadImage(true)"
                            :placeholder="$t('bannerImage')"
                        />
                    </b-form-group>
                </b-col>
                <b-col
                    md="6"
                >
                    <b-form-group
                        :label="$t('homepageImage')"
                        label-for="homepageImage"
                    >
                        <b-form-file
                            id="homepageImage"
                            accept="image/*"
                            v-model="AdInfo.second_image"
                            @input="uploadImage(false)"
                            :placeholder="$t('homepageImage')"
                        />
                    </b-form-group>
                </b-col>
                <b-col
                    md="6"
                >
                    <b-form-group
                        :label="$t('Title')"
                        label-for="mc-title"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="required"
                            :name="$t('Title')"
                        >
                            <b-form-input
                                id="mc-title"
                                :placeholder="$t('Title')"
                                v-model="AdInfo.title"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col
                    md="6"
                >
                    <b-form-group
                        :label="$t('Description')"
                        label-for="mc-description"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="required"
                            :name="$t('Description')"
                        >
                            <b-form-input
                                id="mc-description"
                                :placeholder="$t('Description')"
                                v-model="AdInfo.description"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col
                    md="6"
                >
                    <b-form-group
                        :label="$t('URL')"
                        label-for="mc-url"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="required|url"
                            :name="$t('URL')"
                        >
                            <b-form-input
                                id="mc-url"
                                :placeholder="$t('URL')"
                                v-model="AdInfo.url"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col
                    md="6"
                >
                    <b-form-group
                        :label="$t('Max View Count')"
                        label-for="mc-viewCount"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="required|integer"
                            :name="$t('Max View Count')"
                        >
                            <b-form-input
                                id="mc-viewCount"
                                :placeholder="$t('Max View Count')"
                                v-model="AdInfo.max_view_count"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col
                    md="4"
                >
                    <b-form-group
                        :label="$t('Start Date')"
                        label-for="mc-startDate"
                    >
                        <b-form-datepicker
                            id="mc-startDate"
                            v-model="AdInfo.start_date"
                            menu-class="w-100"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            calendar-width="100%"
                        />
                    </b-form-group>
                </b-col>
                <b-col
                    md="4"
                >
                    <b-form-group
                        :label="$t('End Date')"
                        label-for="mc-endDate"
                    >
                        <b-form-datepicker
                            id="mc-endDate"
                            v-model="AdInfo.end_date"
                            menu-class="w-100"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            calendar-width="100%"
                        />
                    </b-form-group>
                </b-col>
                <b-col
                    md="4"
                >
                    <b-form-group
                        :label="$t('timeRange')"
                        label-for="mc-endDate"
                    >
                        <v-select
                            v-model="AdInfo.times"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            multiple
                            class="select-size-md"
                            label="title"
                            :placeholder="$t('timeRange')"
                            :options="timeRanges"
                        />
                    </b-form-group>

                </b-col>
                <b-col md="12" class="d-flex justify-content-end">
                    <b-button @click="updateAd" variant="primary" class="">
                        {{ $t("update") }}
                    </b-button>
                </b-col>
            </b-row>
        </validation-observer>
    </b-card>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BFormCheckbox,
    BFormInput,
    BFormFile,
    BFormDatepicker, BImg,
} from "bootstrap-vue";
import vSelect from "vue-select";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {
    required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'

export default {
    name: "edit",
    components: {
        BCard, BRow, BCol, BFormGroup, BFormDatepicker,
        BButton,
        BFormCheckbox,
        BFormFile, BImg,
        BFormInput, vSelect,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            loading: true,
            AdInfo: {
                title: '',
                description: '',
                url: '',
                image: null,
                second_image: null,
                start_date: '',
                end_date: '',
                max_view_count: '',
                times: [],
            },
            timeRanges: [],
        }
    },
    methods: {
        uploadImage(isFirstImage) {
            if (isFirstImage) {
                console.log(this.AdInfo.image);
                this.$store.dispatch("ads/uploadImage", this.AdInfo.image)
                    .then(res => {
                        debugger;
                        this.AdInfo.image = res.data.filePath;
                    }).catch(err => {
                })
                    .catch(err => {
                    })
            } else {
                this.$store.dispatch("ads/uploadImage", this.AdInfo.second_image)
                    .then(res => {
                        debugger;
                        this.AdInfo.second_image = res.data.filePath;
                    }).catch(err => {
                })
                    .catch(err => {
                    })
            }

        },
        updateAd() {
            this.$refs.simpleRules.validate().then(result => {
                if (result) {
                    let times = [];
                    for (let time in this.AdInfo.times) {
                        times.push(this.AdInfo.times[time].value)
                    }
                    this.AdInfo.times = times;
                    this.$store.dispatch('ads/updateAd', this.AdInfo)
                        .then(() => {
                            this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: this.$t('success'),
                                        icon: 'CheckCircleIcon',
                                        text: this.$t('Ad updated successfully'),
                                        variant: 'success',
                                    },
                                },
                                {
                                    position: 'bottom-right',
                                });
                            setTimeout(() => {
                                this.$router.push({name: 'ads-list'});
                            }, 1500);
                        })
                        .catch((err) => {
                            this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: this.$t('Error'),
                                        icon: 'InfoIcon',
                                        text: err.response.data.message,
                                        variant: 'danger',
                                    },
                                },
                                {
                                    position: 'bottom-right',
                                })
                        });
                }
            });
        },
    },
    created() {
        this.AdInfo.id = this.$route.params.id;
        this.$store.dispatch("ads/getTimeRanges").then(() => {
            this.timeRanges = this.$store.state.ads.timeRanges;

            //    Get AD info
            this.$store.dispatch('ads/getSingleAd', this.$route.params.id)
                .then((res) => {
                    this.AdInfo = res.data.ad;
                    let formattedTimes = [];
                    for (let i = 0; i < res.data.ad.ad_showing_times.length; i++) {
                        const item = res.data.ad.ad_showing_times[i];
                        let temp = this.timeRanges.find(x => x.value == item);
                        let timeItem = {title: temp.title, value: [item]};
                        formattedTimes.push(timeItem);

                    }
                    this.AdInfo.times = formattedTimes;
                    this.loading = false;
                }).catch(err => {
                this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: this.$t('Error'),
                            icon: 'InfoIcon',
                            text: err.response.data.message,
                            variant: 'danger',
                        },
                    },
                    {
                        position: 'bottom-right',
                    })
            });
        });

    },
}
</script>

<style scoped>
.image-prev {
    object-fit: contain;
    object-position: center;
    aspect-ratio: 30/9;
}
</style>
